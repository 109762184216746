import type { api } from '@meterup/proto';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { graphql } from '../gql';
import { apiInstance, demoApiInstance } from './apiInstance';

const incidentQuery = graphql(`
  query Incident($incidentUUID: UUID!) {
    incident(UUID: $incidentUUID) {
      networkUUID

      network {
        rackElevations {
          UUID
          label
          rackMountUnitCount
          networkUUID
          devices {
            UUID
            label
            rackMountUnitIndexes
            type
            virtualDeviceUUID

            virtualDevice {
              UUID
              label
              deviceType
              deviceModel
              description

              hardwareDevice {
                serialNumber
                deviceType
                deviceModel
                isConnectedToBackend
              }
            }
          }
          notes {
            rackMountUnitIndexStart
            rackMountUnitIndexEnd
            note
          }
        }
      }
    }
  }
`);

export const getDevices = async (incident: string): Promise<api.ControllerDeviceResponse> => {
  const res = await apiInstance({
    method: 'get',
    url: `/v1/incidents/${incident}/devices`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export const getMockDevices = async (): Promise<api.ControllerDeviceResponse> => {
  const res = await demoApiInstance({
    method: 'get',
    url: 'mockPayload.json',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return res.data;
};

export function useIsDemo(id: string) {
  return id === 'demo' && window.location.hostname === 'install.meter.website';
}

function deviceGeneration(name: string): string | undefined {
  const pieces = name.split('-');
  if (pieces.length >= 3) {
    return pieces[1]?.toUpperCase();
  }

  return undefined;
}

export type AccessPointProps = {
  name: string;
  generation?: string;
  status: string;
  speed?: number | null;
};

export type SwitchProps = {
  name: string;
  status: string;
  serialNumber?: string;
  rackMountUnitIndexes?: number[];
};

export function useDevicesData() {
  const { incidentUUID } = useParams();

  if (!incidentUUID) throw new Error('incidentUUID is required');

  const isDemo = useIsDemo(incidentUUID);
  const fetcher = isDemo ? getMockDevices : getDevices;

  const { data: controller, dataUpdatedAt } = useQuery(
    ['controller', incidentUUID],
    (): Promise<api.ControllerDeviceResponse> => fetcher(incidentUUID),
    {
      retry: 2,
      refetchInterval: 10_000,
      refetchIntervalInBackground: false,
    },
  );

  const config2 = useGraphQL(
    incidentQuery,
    {
      incidentUUID,
    },
    {
      enabled: !isDemo,
    },
  ).data?.incident;

  const sortedAPs = groupBy(
    controller?.access_points,
    (ap: { connected_status: string }) => ap.connected_status,
  );

  const accessPoints: AccessPointProps[] | undefined = controller?.access_points.map((ap) => ({
    name: ap.location ? ap.location : ap.name,
    generation: deviceGeneration(ap.name),
    status: ap.connected_status,
    speed: ap.link_speed_mbps,
  }));

  const config1Switches: SwitchProps[] =
    controller?.switches.map((s) => ({
      name: s.location,
      status: s.connected_status,
    })) ?? [];

  const config2Switches: SwitchProps[] =
    config2?.network?.rackElevations
      .flatMap((e) => e.devices)
      .filter((d) => d.type === 'METER_SWITCH')
      .map((d) => ({
        name: d.label || d.virtualDevice?.label || d.type || 'Meter Switch',
        status: d.virtualDevice?.hardwareDevice?.isConnectedToBackend ? 'online' : 'offline',
        serialNumber: d.virtualDevice?.hardwareDevice?.serialNumber,
        rackMountUnitIndexes: d.rackMountUnitIndexes,
      })) ?? [];

  const switches = [...config1Switches, ...config2Switches];

  const sortedSwitches = groupBy(switches, (s: { status: string }) => s.status);

  // just for type safety; we use suspense so this should never be undefined
  if (!controller) {
    throw new Error('controller is undefined');
  }

  return {
    controller,
    accessPoints,
    switches,
    sortedAPs,
    sortedSwitches,
    dataUpdatedAt,
  };
}
