import type { BandSize, BandVariant } from '@meterup/atto';
import { BandWithIcon } from '@meterup/atto';
import { styled } from '@meterup/common';
import React from 'react';

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  paddingBottom: 13,
});

const BadgeRegion = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 2,
  left: 0,
  right: 0,
});

type BandIconAndBadgeProps = {
  icon: React.ReactNode;
  badge: React.ReactNode;
  variant: BandVariant;
  size: BandSize;
};

export function BandIconAndBadge({ variant, size, icon, badge }: BandIconAndBadgeProps) {
  return (
    <Container>
      <BandWithIcon size={size} variant={variant}>
        {icon}
      </BandWithIcon>
      <BadgeRegion>{badge}</BadgeRegion>
    </Container>
  );
}
