import axios from 'axios';

export const API_BASE_URL = import.meta.env.REACT_APP_API_URL;

export const apiInstance = axios.create({
  withCredentials: true,
  baseURL: `${API_BASE_URL}/api-proxy`,
});

export const demoApiInstance = axios.create({
  withCredentials: true,
  baseURL: '/@fs/dev-data/',
});
