import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query Incident($incidentUUID: UUID!) {\n    incident(UUID: $incidentUUID) {\n      networkUUID\n\n      network {\n        rackElevations {\n          UUID\n          label\n          rackMountUnitCount\n          networkUUID\n          devices {\n            UUID\n            label\n            rackMountUnitIndexes\n            type\n            virtualDeviceUUID\n\n            virtualDevice {\n              UUID\n              label\n              deviceType\n              deviceModel\n              description\n\n              hardwareDevice {\n                serialNumber\n                deviceType\n                deviceModel\n                isConnectedToBackend\n              }\n            }\n          }\n          notes {\n            rackMountUnitIndexStart\n            rackMountUnitIndexEnd\n            note\n          }\n        }\n      }\n    }\n  }\n':
    types.IncidentDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Incident($incidentUUID: UUID!) {\n    incident(UUID: $incidentUUID) {\n      networkUUID\n\n      network {\n        rackElevations {\n          UUID\n          label\n          rackMountUnitCount\n          networkUUID\n          devices {\n            UUID\n            label\n            rackMountUnitIndexes\n            type\n            virtualDeviceUUID\n\n            virtualDevice {\n              UUID\n              label\n              deviceType\n              deviceModel\n              description\n\n              hardwareDevice {\n                serialNumber\n                deviceType\n                deviceModel\n                isConnectedToBackend\n              }\n            }\n          }\n          notes {\n            rackMountUnitIndexStart\n            rackMountUnitIndexEnd\n            note\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Incident($incidentUUID: UUID!) {\n    incident(UUID: $incidentUUID) {\n      networkUUID\n\n      network {\n        rackElevations {\n          UUID\n          label\n          rackMountUnitCount\n          networkUUID\n          devices {\n            UUID\n            label\n            rackMountUnitIndexes\n            type\n            virtualDeviceUUID\n\n            virtualDevice {\n              UUID\n              label\n              deviceType\n              deviceModel\n              description\n\n              hardwareDevice {\n                serialNumber\n                deviceType\n                deviceModel\n                isConnectedToBackend\n              }\n            }\n          }\n          notes {\n            rackMountUnitIndexStart\n            rackMountUnitIndexEnd\n            note\n          }\n        }\n      }\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
