import { EmptyState, VStack } from '@meterup/atto';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

function ErrorBoundary() {
  const error = useRouteError();

  // eslint-disable-next-line no-console
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <EmptyState
          heading="Error"
          subheading={
            <VStack>
              <p>{error.statusText || error.data}</p>
            </VStack>
          }
        />
      </div>
    );
  }

  if (error instanceof Error) {
    return (
      <div>
        <EmptyState
          heading="Error"
          subheading={
            <VStack>
              <p>{error.message}</p>
            </VStack>
          }
        />
      </div>
    );
  }

  return (
    <div>
      <EmptyState
        heading="No access"
        subheading={
          <p>
            You don't have access to this installation.
            <br />
            Please reach out to Meter.
          </p>
        }
      />
    </div>
  );
}

export default ErrorBoundary;
