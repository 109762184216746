import { fade, palette } from '../../common/colors';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

const CodeContent = styled(Text, {
  position: 'relative',
  zIndex: 1,
  color: colors.bodyBrandLight,
  fontFamily: '$mono',

  [darkThemeSelector]: {
    color: colors.bodyBrandDark,
  },
});

const CodeContainer = styled('span', {
  position: 'relative',
  padding: '0 $2',

  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: 0,
    display: 'flex',
    inset: '-$2 -$1',
    backgroundColor: fade(palette.tokenBgNeutralLight, 0.5),
    strokeAll: fade(palette.tokenStrokeNeutralLight, 0.5),
    borderRadius: '$6',

    [darkThemeSelector]: {
      backgroundColor: fade(palette.tokenBgNeutralDark, 0.3),
      strokeAll: fade(palette.tokenStrokeNeutralDark, 0.3),
    },
  },
});

export function Code({ children }: { children: React.ReactNode }) {
  return (
    <CodeContainer>
      <CodeContent>{children}</CodeContent>
    </CodeContainer>
  );
}
