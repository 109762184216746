import type { ChangeEvent, ReactNode } from 'react';
import type {
  Key as ReactAriaKey,
  TagGroupProps as ReactAriaTagGroupProps,
  TagListProps as ReactAriaTagListProps,
  TagProps as ReactAriaTagProps,
} from 'react-aria-components';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button as ReactAriaButton,
  Tag as ReactAriaTag,
  TagGroup as ReactAriaTagGroup,
  TagList as ReactAriaTagList,
} from 'react-aria-components';
import { useDebounce } from 'use-debounce';

import type { DynamicIconName } from '../../assets/DynamicIcon/DynamicIcon';
import type { VariantProp } from '../../common/colors';
import type { ControlSize } from '../shared/types';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { useControlSize } from '../../common/control_size';
import { contentStyles } from '../../common/menus';
import { zIndexes } from '../../common/z_indexes';
import { BaseInput } from '../../components/BaseInput/BaseInput';
import {
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { Menu, MenuItem } from '../Menu/Menu';
import { selectors, transitions } from '../shared/styles';

const TokenInputTokenIcon = styled(DynamicIcon, {
  color: '$$iconColor',
});

const TokenInputTokenValue = styled('span', {
  display: 'flex',
  color: '$$valueColor',
  fontWeight: fontWeights.medium,
});

const TokenInputTokenRemoveIcon = styled(Icon, {
  color: '$$removeColor',

  variants: {
    size: {
      'x-small': {
        width: '$10',
        height: '$10',
      },
      small: {
        width: '$10',
        height: '$10',
      },
      medium: {
        width: '$12',
        height: '$12',
      },
      large: {
        width: '$14',
        height: '$14',
      },
      'x-large': {
        width: '$14',
        height: '$14',
      },
    },
  },
});

const TokenInputTokenRemove = styled(ReactAriaButton, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '1lh',

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  variants: {
    size: {
      'x-small': {
        borderRadius: '$2',
      },
      small: {
        borderRadius: '$2',
      },
      medium: {
        borderRadius: '$4',
      },
      large: {
        borderRadius: '$4',
      },
      'x-large': {
        borderRadius: '$4',
      },
    },
  },
});

const TokenInputTokenContainer = styled(ReactAriaTag, {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  color: '$$valueColor',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    size: {
      'x-small': {
        padding: '0 $2',
        borderRadius: '$4',
        ...SmallSansSizes,
      },
      small: {
        padding: '0 $2',
        borderRadius: '$4',
        ...SmallSansSizes,
      },
      medium: {
        padding: '0 $4',
        borderRadius: '$6',
        ...BodySansSizes,
      },
      large: {
        padding: '$4 $8',
        borderRadius: '$8',
        ...BodySansSizes,
      },
      'x-large': {
        padding: '$4 $8',
        borderRadius: '$8',
        ...LargeSansSizes,
      },
    },
    variant: {
      brand: {
        background: colors.tokenBgBrandLight,
        strokeAll: colors.tokenStrokeBrandLight,
        $$iconColor: colors.iconBrandLight,
        $$valueColor: colors.bodyBrandLight,
        $$removeColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          background: colors.tokenBgBrandDark,
          strokeAll: colors.tokenStrokeBrandDark,
          $$iconColor: colors.iconBrandDark,
          $$valueColor: colors.bodyBrandDark,
          $$removeColor: colors.iconBrandDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyBrandLight,
          $$valueColor: colors.headingBrandLight,
          $$removeColor: colors.bodyBrandLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyBrandDark,
            $$valueColor: colors.headingBrandDark,
            $$removeColor: colors.bodyBrandDark,
          },
        },
      },
      alternative: {
        background: colors.tokenBgAlternativeLight,
        strokeAll: colors.tokenStrokeAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,
        $$valueColor: colors.bodyAlternativeLight,
        $$removeColor: colors.iconAlternativeLight,

        [darkThemeSelector]: {
          background: colors.tokenBgAlternativeDark,
          strokeAll: colors.tokenStrokeAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
          $$valueColor: colors.bodyAlternativeDark,
          $$removeColor: colors.iconAlternativeDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyAlternativeLight,
          $$valueColor: colors.headingAlternativeLight,
          $$removeColor: colors.bodyAlternativeLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyAlternativeDark,
            $$valueColor: colors.headingAlternativeDark,
            $$removeColor: colors.bodyAlternativeDark,
          },
        },
      },
      attention: {
        background: colors.tokenBgAttentionLight,
        strokeAll: colors.tokenStrokeAttentionLight,
        $$iconColor: colors.iconAttentionLight,
        $$valueColor: colors.bodyAttentionLight,
        $$removeColor: colors.iconAttentionLight,

        [darkThemeSelector]: {
          background: colors.tokenBgAttentionDark,
          strokeAll: colors.tokenStrokeAttentionDark,
          $$iconColor: colors.iconAttentionDark,
          $$valueColor: colors.bodyAttentionDark,
          $$removeColor: colors.iconAttentionDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyAttentionLight,
          $$valueColor: colors.headingAttentionLight,
          $$removeColor: colors.bodyAttentionLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyAttentionDark,
            $$valueColor: colors.headingAttentionDark,
            $$removeColor: colors.bodyAttentionDark,
          },
        },
      },
      negative: {
        background: colors.tokenBgNegativeLight,
        strokeAll: colors.tokenStrokeNegativeLight,
        $$iconColor: colors.iconNegativeLight,
        $$valueColor: colors.bodyNegativeLight,
        $$removeColor: colors.iconNegativeLight,

        [darkThemeSelector]: {
          background: colors.tokenBgNegativeDark,
          strokeAll: colors.tokenStrokeNegativeDark,
          $$iconColor: colors.iconNegativeDark,
          $$valueColor: colors.bodyNegativeDark,
          $$removeColor: colors.iconNegativeDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyNegativeLight,
          $$valueColor: colors.headingNegativeLight,
          $$removeColor: colors.bodyNegativeLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyNegativeDark,
            $$valueColor: colors.headingNegativeDark,
            $$removeColor: colors.bodyNegativeDark,
          },
        },
      },
      neutral: {
        background: colors.tokenBgNeutralLight,
        strokeAll: colors.tokenStrokeNeutralLight,
        $$iconColor: colors.iconNeutralLight,
        $$valueColor: colors.bodyNeutralLight,
        $$removeColor: colors.iconNeutralLight,

        [darkThemeSelector]: {
          background: colors.tokenBgNeutralDark,
          strokeAll: colors.tokenStrokeNeutralDark,
          $$iconColor: colors.iconNeutralDark,
          $$valueColor: colors.bodyNeutralDark,
          $$removeColor: colors.iconNeutralDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyNeutralLight,
          $$valueColor: colors.headingNeutralLight,
          $$removeColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyNeutralDark,
            $$valueColor: colors.headingNeutralDark,
            $$removeColor: colors.bodyNeutralDark,
          },
        },
      },
      positive: {
        background: colors.tokenBgPositiveLight,
        strokeAll: colors.tokenStrokePositiveLight,
        $$iconColor: colors.iconPositiveLight,
        $$valueColor: colors.bodyPositiveLight,
        $$removeColor: colors.iconPositiveLight,

        [darkThemeSelector]: {
          background: colors.tokenBgPositiveDark,
          strokeAll: colors.tokenStrokePositiveDark,
          $$iconColor: colors.iconPositiveDark,
          $$valueColor: colors.bodyPositiveDark,
          $$removeColor: colors.iconPositiveDark,
        },

        [selectors.hover]: {
          $$iconColor: colors.bodyPositiveLight,
          $$valueColor: colors.headingPositiveLight,
          $$removeColor: colors.bodyPositiveLight,

          [darkThemeSelector]: {
            $$iconColor: colors.bodyPositiveDark,
            $$valueColor: colors.headingPositiveDark,
            $$removeColor: colors.bodyPositiveDark,
          },
        },
      },
    },
  },
});

const getTokenInputTokenIconSize = (size: ControlSize) => {
  switch (size) {
    case 'x-small':
      return 12;
    case 'small':
      return 14;
    case 'medium':
      return 16;
    case 'large':
      return 16;
    case 'x-large':
      return 16;
    default:
      return 16;
  }
};

type TokenInputTokenProps = ReactAriaTagProps & {
  icon?: DynamicIconName;
  size?: ControlSize;
  variant?: VariantProp;
};

const getTokenGap = (size: ControlSize) => {
  switch (size) {
    case 'x-small':
    case 'small':
      return 2;
    case 'large':
    case 'x-large':
      return 6;
    default:
      return 4;
  }
};

const getTokenPreset = (size: ControlSize) => {
  switch (size) {
    case 'x-small':
    case 'small':
      return 'small';
    case 'x-large':
      return 'large';
    default:
      return 'body';
  }
};

function TokenInputToken({
  children,
  icon,
  size = 'medium',
  variant = 'brand',
  ...props
}: TokenInputTokenProps) {
  return (
    <TokenInputTokenContainer
      textValue={children as string}
      variant={variant}
      size={size}
      {...props}
    >
      <AlignStack
        direction="row"
        gap={getTokenGap(size)}
        preset={getTokenPreset(size)}
        start={icon && <TokenInputTokenIcon icon={icon} size={getTokenInputTokenIconSize(size)} />}
        end={
          <TokenInputTokenRemove size={size} slot="remove">
            <TokenInputTokenRemoveIcon icon="cross" size={size} />
          </TokenInputTokenRemove>
        }
      >
        <TokenInputTokenValue>{children as ReactNode}</TokenInputTokenValue>
      </AlignStack>
    </TokenInputTokenContainer>
  );
}

const TokenInputTokensList = styled(ReactAriaTagList, {
  display: 'flex',
  flexWrap: 'wrap',
  outline: 'none',

  variants: {
    size: {
      'x-small': {
        gap: '$4',
        padding: '$2 $4',
      },
      small: {
        gap: '$4',
        padding: '$2 $6',
      },
      medium: {
        gap: '$4',
        padding: '$4 $6',
      },
      large: {
        gap: '$6',
        padding: '$4 $8',
      },
      'x-large': {
        gap: '$8',
        padding: '$6 $8',
      },
    },
  },
});

const TokenInputTokensContainer = styled(ReactAriaTagGroup, {
  display: 'flex',
  alignItems: 'center',
});

interface TokenInputTokensProps<T>
  extends Omit<ReactAriaTagGroupProps, 'children'>,
    Pick<ReactAriaTagListProps<T>, 'items' | 'children' | 'renderEmptyState'> {
  size?: ControlSize;
  variant?: VariantProp;
}

function TokenInputTokens<T extends object>({
  items,
  children,
  renderEmptyState,
  size,
  ...props
}: TokenInputTokensProps<T>) {
  return (
    <TokenInputTokensContainer {...props}>
      <TokenInputTokensList items={items} renderEmptyState={renderEmptyState} size={size}>
        {children as ReactNode}
      </TokenInputTokensList>
    </TokenInputTokensContainer>
  );
}

const TokenInputField = styled(BaseInput, {
  display: 'flex',
  flex: 1,
  minWidth: '$120',
});

const TokenInputContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  minWidth: 'min-content',
  height: 'min-content',
  background: colors.bgApplicationLight,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),

  $$valueColor: colors.controlContentValueLight,
  $$placeholderColor: colors.controlContentPlaceholderLight,

  [selectors.focus]: {
    outline: 'none',
  },

  [selectors.focusWithin]: {
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),

    $$valueColor: colors.controlContentValueDark,
    $$placeholderColor: colors.controlContentPlaceholderDark,
  },

  '&[aria-disabled]': {
    background: colors.gray50,
    strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
    opacity: 0.5,
    pointerEvents: 'none',

    [darkThemeSelector]: {
      background: colors.gray900,
      strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
    },
  },

  variants: {
    isInvalid: {
      true: {
        strokeAllThick: colors.controlStrokeBaseErrorLight,

        [darkThemeSelector]: {
          strokeAll: colors.controlStrokeBaseErrorDark,
        },
      },
    },
    size: {
      'x-small': {
        minHeight: '$20',
        borderRadius: '$6',
      },
      small: {
        minHeight: '$24',
        borderRadius: '$8',
      },
      medium: {
        minHeight: '$28',
        borderRadius: '$8',
      },
      large: {
        minHeight: '$36',
        borderRadius: '$8',
      },
      'x-large': {
        minHeight: '$44',
        borderRadius: '$8',
      },
    },
  },
});

const TokenInputMenuContainer = styled('div', contentStyles, {
  padding: '$4',
  zIndex: zIndexes.dialogContent,
});

export type TokenType = {
  id: string;
  name: string;
};

type CommonTokenInputProps = {
  id?: string;
  options?: TokenType[];
  creatable?: boolean;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>;
  tagListProps: { 'aria-label': string };
  getTokenProps?: (item: TokenType) => TokenInputTokenProps;
  getTokenName?: (key: string) => string;
};

type TokenInputProps = CommonTokenInputProps &
  // discriminated union for controlled/uncontrolled TokenInput value
  (| { value: string[]; onValueChange: (keys: Set<string>) => void; defaultValue?: string[] }
    | {
        defaultValue?: string[];
        onValueChange?: undefined;
        value?: undefined;
      }
  ) &
  // discriminated union for controlled/uncontrolled query value
  (| {
        /**
         * the value of the underlying text input. Used for a controlled TokenInput
         * */
        query: string;
        onQueryChange: (value: string) => void;
      }
    | {
        query?: undefined;
        onQueryChange?: undefined;
      }
  ) & {
    size?: ControlSize;
  };

export function TokenInput({
  id = 'tokenInput',
  size = 'medium',
  onValueChange,
  onQueryChange,
  query,
  options,
  creatable = false,
  inputProps,
  tagListProps,
  getTokenProps = () => ({}),
  getTokenName,
  defaultValue: defaultValueProp,
  value,
}: TokenInputProps) {
  const isControlled = onQueryChange != null;
  const controlSize = useControlSize(size, 'medium');

  let defaultValue: TokenType[] = [];
  if (defaultValueProp) {
    defaultValue = [
      ...defaultValueProp.map((key) => ({
        id: key,
        name: getTokenName ? getTokenName(key) : key,
      })),
    ];
  }
  if (value) {
    defaultValue = Array.from(value).map((key) => ({
      id: key,
      name: getTokenName ? getTokenName(key) : key,
    }));
  }
  const [internalTokens, setInternalTokens] =
    useState<{ id: string; name: string }[]>(defaultValue);
  const tokens = value
    ? value.map((key) => ({ id: key, name: getTokenName ? getTokenName(key) : key }))
    : internalTokens;
  const inputRef = useRef<HTMLInputElement>(null);

  const [internalQuery, setInternalQuery] = useState(query ?? '');
  const [debouncedInternalQuery] = useDebounce(internalQuery, 100);

  const handleOnChange = useCallback(
    (values: { id: string; name: string }[]) => {
      setInternalTokens(values);
      if (onValueChange) {
        onValueChange(new Set(values.map((token) => token.id)));
      }
      setInternalQuery('');
      if (onQueryChange) {
        onQueryChange('');
      }
    },
    [setInternalTokens, onValueChange, onQueryChange],
  );

  const onRemove = useCallback(
    (keys: Set<ReactAriaKey>) => {
      setInternalTokens((prevTokens) =>
        Array.from(prevTokens).filter((item) => !keys.has(item.id)),
      );
      const newVal = new Set(
        tokens.map((token) => token.id).filter((tokenId) => !keys.has(tokenId)),
      );
      if (onValueChange) {
        onValueChange(newVal);
      }
      if (newVal.size === 0 && inputRef?.current) {
        inputRef.current.focus();
      }
    },
    [tokens, onValueChange],
  );

  const handleOnClose = useCallback(() => {
    setInternalQuery('');
    if (onQueryChange) {
      onQueryChange('');
    }
  }, [setInternalQuery, onQueryChange]);

  const handleQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      if (onQueryChange) {
        onQueryChange(val);
      }
      setInternalQuery(val);
    },
    [onQueryChange],
  );

  const filteredOptions = useMemo(() => {
    if (isControlled || !debouncedInternalQuery) return options;

    const lowerCaseQuery = debouncedInternalQuery.toLowerCase();

    return options?.filter((option) => option.name.toLowerCase().includes(lowerCaseQuery));
  }, [isControlled, options, debouncedInternalQuery]);

  return (
    <Combobox
      as="div"
      value={tokens ?? null}
      onChange={handleOnChange}
      onClose={handleOnClose}
      multiple
      immediate
    >
      <TokenInputContainer size={controlSize}>
        {tokens.length > 0 && (
          <TokenInputTokens {...tagListProps} items={tokens} onRemove={onRemove} size={size}>
            {(item) => (
              <TokenInputToken {...getTokenProps(item)} key={item.id} size={controlSize}>
                {item.name}
              </TokenInputToken>
            )}
          </TokenInputTokens>
        )}
        <TokenInputField
          controlSize={controlSize}
          isNested
          shouldApplyFocusStyles={false}
          inputRef={inputRef}
          inputProps={{
            ...inputProps,
            placeholder: inputProps?.placeholder ?? 'Select...',
            as: ComboboxInput,
            value: query ?? internalQuery,
            onChange: handleQueryChange,
          }}
        />
      </TokenInputContainer>
      {((filteredOptions && filteredOptions?.length > 0) || (creatable && query)) && (
        <ComboboxOptions
          className={`disableOutsideClick_${id}`}
          anchor="bottom start"
          modal
          as={TokenInputMenuContainer}
        >
          <Menu>
            {creatable && query && (
              <ComboboxOption value={{ id: query, name: query }}>
                {({ focus, selected }) => (
                  <MenuItem active={focus} enabled={selected}>
                    Create "{query}"
                  </MenuItem>
                )}
              </ComboboxOption>
            )}
            {filteredOptions?.map((token) => (
              <ComboboxOption key={token.id} value={token}>
                {({ focus, selected }) => (
                  <MenuItem active={focus} enabled={selected}>
                    {token.name}
                  </MenuItem>
                )}
              </ComboboxOption>
            ))}
          </Menu>
        </ComboboxOptions>
      )}
    </Combobox>
  );
}
