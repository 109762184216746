import { HStack, Logo, space, styled } from '@meterup/atto';
import { shadows } from '@meterup/common';
import React from 'react';

const Container = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 80px 1fr',
  alignItems: 'center',
  justifyContent: 'center',
  paddingX: space(14),
  boxShadow: shadows.navbarLight,
  backgroundColor: '$white',
  position: 'relative',
  zIndex: 5,
});

const Title = styled('h1', {
  fontSize: '$lg',
  fontWeight: '$medium',
  color: '$brand700',
});

type NavbarProps = {
  startActions?: React.ReactNode;
  endActions?: React.ReactNode;
  title?: string;
};

export default function Navbar(props: NavbarProps) {
  return (
    <Container>
      <HStack align="center" justify="start" spacing={space(4)}>
        {props.startActions}
      </HStack>
      <HStack justify="center">
        {props.title ? <Title>{props.title}</Title> : <Logo size={20} type="word" />}
      </HStack>
      <HStack align="center" justify="end" spacing={space(4)}>
        {props.endActions}
      </HStack>
    </Container>
  );
}
