import React from 'react';

import type { ControlSize, ControlVariant } from '../shared/types';
import { ControlSizeProvider } from '../../common/control_size';
import { ControlVariantProvider } from '../../common/control_variant';
import { BaseInputContainer } from '../../components/BaseInput/BaseInput';
import { SelectContainer, SelectTrigger } from '../../components/Select/SelectTrigger';
import { styled } from '../../stitches.config';
import { ButtonContainer } from '../Button/Button';

const resetChildSelector = `
& ${ButtonContainer}, 
& ${SelectContainer} ${SelectTrigger}, 
& > ${SelectTrigger}, 
& ${BaseInputContainer}`;

const firstChildSelector = `
& ${ButtonContainer}:first-child, 
& ${SelectContainer}:first-child ${SelectTrigger} button, 
& > ${SelectTrigger}:first-child, 
& ${BaseInputContainer}:first-child`;

const lastChildSelector = `
& ${ButtonContainer}:last-child, 
& ${SelectContainer}:last-child ${SelectTrigger}, 
& > ${SelectTrigger}:last-child, 
& ${BaseInputContainer}:last-child`;

const ControlGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    relation: {
      joined: {
        [resetChildSelector]: {
          borderRadius: 0,
        },
      },
      separate: {},
    },
    size: {
      'x-small': {},
      small: {},
      medium: {},
      large: {},
      'x-large': {},
    },
    wrap: {
      wrap: {
        flexWrap: 'wrap',
      },
      'no-wrap': {
        flexWrap: 'nowrap',
      },
    },
  },

  compoundVariants: [
    {
      relation: 'separate',
      size: 'x-small',
      css: {
        gap: '$4',
      },
    },
    {
      relation: 'separate',
      size: 'small',
      css: {
        gap: '$6',
      },
    },
    {
      relation: 'separate',
      size: 'medium',
      css: {
        gap: '$8',
      },
    },
    {
      relation: 'separate',
      size: 'large',
      css: {
        gap: '$10',
      },
    },
    {
      relation: 'separate',
      size: 'x-large',
      css: {
        gap: '$12',
      },
    },
    {
      relation: 'joined',
      size: 'x-small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$6',
          borderBottomLeftRadius: '$6',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$6',
          borderBottomRightRadius: '$6',
        },
      },
    },
    {
      relation: 'joined',
      size: 'small',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderBottomLeftRadius: '$8',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      relation: 'joined',
      size: 'medium',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$8',
          borderBottomLeftRadius: '$8',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$8',
          borderBottomRightRadius: '$8',
        },
      },
    },
    {
      relation: 'joined',
      size: 'large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderBottomLeftRadius: '$10',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },
    {
      relation: 'joined',
      size: 'x-large',
      css: {
        [firstChildSelector]: {
          borderTopLeftRadius: '$10',
          borderBottomLeftRadius: '$10',
        },

        [lastChildSelector]: {
          borderTopRightRadius: '$10',
          borderBottomRightRadius: '$10',
        },
      },
    },
  ],
});

export type ControlGroupProps = {
  children: React.ReactNode;
  size?: ControlSize;
  relation?: 'joined' | 'separate';
  variant?: ControlVariant;
  wrap?: 'wrap' | 'no-wrap';
};

export function ControlGroup({
  children,
  size = 'medium',
  relation = 'joined',
  variant,
  wrap,
  ...remaining
}: ControlGroupProps) {
  return (
    <ControlGroupContainer size={size} relation={relation} wrap={wrap} {...remaining}>
      <ControlSizeProvider value={size}>
        <ControlVariantProvider value={variant}>{children}</ControlVariantProvider>
      </ControlSizeProvider>
    </ControlGroupContainer>
  );
}
