import './index.css';
import '@meterup/atto/src/styles/fonts.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import DevicesInfo from './components/DevicesInfo';
import ErrorBoundary from './ErrorBoundary';
import { initializePosthog } from './utils/initializePosthog';

initializePosthog();

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        // TODO: replace with a nicer index page
        element: <ErrorBoundary />,
      },
      {
        path: '/:incidentUUID',
        element: <DevicesInfo />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
