import type { ComponentProps } from 'react';
import React from 'react';

import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import type { Breakpoint } from '../../utilities/useViewport';
import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { styled } from '../../stitches.config';
import { useViewport } from '../../utilities/useViewport';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const PaneHeaderContainer = styled(ShellHeader);

export type PaneHeaderProps = ShellHeaderProps;

export function PaneHeader({ ...remaining }: PaneHeaderProps) {
  return <PaneHeaderContainer {...remaining} />;
}

export type PaneContentProps = ShellContentProps;

const PaneContentContainer = styled(ShellContent);

export const PaneContent = React.forwardRef(
  <Tag extends React.ElementType>(
    { background, children, gutter = 'none', ...remaining }: PaneContentProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <PaneContentContainer gutter={gutter} background={background} {...remaining} ref={ref}>
      {children}
    </PaneContentContainer>
  ),
);

const PaneFooterContainer = styled(ShellFooter);

export type PaneFooterProps = ShellFooterProps & {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Fallback to `children` if `actions` or `secondaryActions` are not provided.
   */
  children?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
} & ComponentProps<typeof PaneFooterContainer>;

export function PaneFooter({ actions, children, secondaryActions, ...remaining }: PaneFooterProps) {
  return <PaneFooterContainer start={secondaryActions} end={actions || children} {...remaining} />;
}

export const PaneContainer = styled(Shell, {
  gridArea: 'pane',
  width: '100%',

  variants: {
    preset: {
      full: {},
      intermediate: {
        '@mobile': {
          height: '100%',
        },

        '@notMobile': {
          paddingTop: '$32',
        },
      },
      narrow: {
        '@mobile': {
          height: '100%',
        },

        '@notMobile': {
          paddingTop: '$32',
        },
      },
    },
  },
});

export type ShellPresetProp = 'narrow' | 'intermediate' | 'full';

export type PaneProps = ShellProps & {
  preset?: ShellPresetProp;
} & ComponentProps<typeof PaneContainer>;

const getPaneMaxWidth = (preset: ShellPresetProp, breakpoint: Breakpoint) => {
  switch (preset) {
    case 'intermediate':
      return breakpoint !== 'mobile' ? 1024 : undefined;
    case 'narrow':
      return breakpoint !== 'mobile' ? 760 : undefined;
    default:
      return 'full';
  }
};

export function Pane({ children, preset = 'full', ...remaining }: PaneProps) {
  const { breakpoint } = useViewport();
  return (
    <PaneContainer
      preset={preset}
      maxWidth={getPaneMaxWidth(preset, breakpoint)}
      widthMode={preset === 'full' ? 'full' : 'centered'}
      {...remaining}
    >
      {children}
    </PaneContainer>
  );
}
