const layers = {
  base: 0,
  dock: 100,
  sidebar: 200,
  stage: 300,
  pane: 400,
  dialog: 700,
  overlay: 1000,
  alert: 2000,
} as const;

export const zIndexes = {
  // Base
  base: layers.base,

  // Dock
  dock: layers.dock,
  dockFloating: layers.dock + 10,

  // Sidebar
  sidebar: layers.sidebar,
  sidebarFloating: layers.sidebar + 10,
  sidebarPop: layers.pane + 1,

  // Stage
  stage: layers.stage,

  // Panes
  pane: layers.pane,
  paneDrawer: layers.pane + 1,

  // Dialogs
  dialog: layers.dialog,
  dialogContent: layers.dialog + 1,

  // Overlays
  overlay: layers.overlay,
  overlayWash: layers.overlay - 1,

  // Alerts
  alert: layers.alert,
};
