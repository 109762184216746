import type { IconProps } from '@meterup/atto';
import { HStack, Icon, space, styled, Text, VStack } from '@meterup/atto';
import React from 'react';

const Heading = styled('h2', Text, {
  margin: 0,
  whiteSpace: 'nowrap',
  fontSize: '$16',
  lineHeight: '$20',
  color: '$gray800',
});

export function Section({
  icon,
  children,
  title,
}: {
  children: React.ReactNode;
  icon: IconProps['icon'];
  title: string;
}) {
  return (
    <VStack spacing={space(8)}>
      <HStack spacing={space(8)} justify="start" align="center">
        <Icon icon={icon} size={16} />
        <Heading>{title}</Heading>
      </HStack>
      {children}
    </VStack>
  );
}
