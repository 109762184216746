import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const TitleBase = {
  color: colors.headingNeutralLight,
  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
};

export const TitleSansSizes = {
  '@notDesktop': {
    fontSize: '$28',
    lineHeight: '$36',
  },
  '@desktop': {
    fontSize: '$24',
    lineHeight: '$32',
  },
};

export const TitleMonoSizes = {
  '@notDesktop': {
    fontSize: '$26',
    lineHeight: '$36',
  },
  '@desktop': {
    fontSize: '$22',
    lineHeight: '$32',
  },
};

export const Title = styled(Text, TitleBase, {
  variants: {
    context: {
      body: {},
      heading: {},
    },
    family: {
      brand: TitleSansSizes,
      monospace: TitleMonoSizes,
    },
    weight: {
      bold: {},
    },
  },
  defaultVariants: {
    context: 'body',
    family: 'brand',
    weight: 'bold',
  },
});
